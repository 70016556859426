import React, { Component } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import './scss/style.scss'
import BackToTop from './components/common/BackToTop'
import {
  getSpinnerMessage,
  getToastErrors,
  getToastMessage,
  isShowErrorToast,
  isShowSpinner,
  isShowSuccessToast,
} from './views/selectors'
import { connect } from 'react-redux'
import ErrorToast from './components/notifications/ErrorToast'
import SuccessToast from './components/notifications/SuccessToast'
import { ZToaster } from './shared'
import { getEmailVerifiedAt, makeSelectTokenId } from './views/user/selectors'
import LoadingSpinner from './components/LoadingSpinner'
import { hideErrorToastAction, hideSpinnerAction, hideSuccessToastAction } from './views/actions'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/user/login/Login'))
const Register = React.lazy(() => import('./views/user/register/Register'))
const Register2 = React.lazy(() => import('./views/user/register-2/Register2'))
const EmailVerify = React.lazy(() => import('./views/user/email-verify'))
const AddBusiness = React.lazy(() => import('./views/user/add-business/AddBusiness'))
const SelectCompany = React.lazy(() => import('./views/user/select-company'))
const LandingPage = React.lazy(() => import('./views/pages/landing'))
const AddPaymentMethod = React.lazy(() =>
  import('./views/user/add-payment-method/AddPaymentMethod'),
)
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const Thankyou = React.lazy(() => import('./views/user/thank-you/Thankyou'))
const ForgotPassword = React.lazy(() => import('./views/user/forgot-password/ForgotPassword'))
const VerifyResetCode = React.lazy(() => import('./views/user/forgot-password/VerifyResetCode'))
const NewPassword = React.lazy(() => import('./views/user/forgot-password/NewPassword'))

class App extends Component {
  constructor() {
    super()
    this.state = {
      toast: null,
    }
  }
  componentDidMount() {
    this.props.hideErrorToast()
    this.props.hideSuccessToast()
    this.props.hideSpinner()
  }

  render() {
    return (
      <>
        {this.props.isShowErrorToast && this.props.toastErrors && (
          <ZToaster push={<ErrorToast errors={this.props.toastErrors} />} placement="top-end" />
        )}
        {this.props.isShowSuccessToast && this.props.toastMessage && (
          <ZToaster push={<SuccessToast message={this.props.toastMessage} />} placement="top-end" />
        )}
        {this.props.isShowSpinner && this.props.spinnerMessage && (
          <LoadingSpinner message={this.props.spinnerMessage} />
        )}
        <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/register"
                name="Register Page"
                render={(props) => <Register {...props} />}
              />
              <Route
                exact
                path="/email-verification"
                name="Email Verification Page"
                render={(props) => <EmailVerify {...props} />}
              />
              <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
              <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
              <Route
                exact
                path="/lp-thank-you"
                name="Thank you LP Page"
                render={(props) => <Thankyou {...props} />}
              />
              <Route
                exact
                path="/thank-you"
                name="Thank you Page"
                render={(props) => <Thankyou {...props} />}
              />
              <Route
                exact
                path="/checkpoint/reset-password"
                name="Forgot Password"
                render={(props) => <ForgotPassword {...props} />}
              />
              <Route
                exact
                path="/checkpoint/verification-code"
                name="Verification Code"
                render={(props) => <VerifyResetCode {...props} />}
              />
              <Route
                exact
                path="/checkpoint/new-password"
                name="New Password"
                render={(props) => <NewPassword {...props} />}
              />
              {this.props.token && this.props.isEmailVerified && (
                <>
                  <Route
                    exact
                    path="/add-business"
                    name="Add Business Page"
                    render={(props) => <AddBusiness {...props} />}
                  />
                  <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
                </>
              )}
              <Redirect from="/" to="/login" />
            </Switch>
            <BackToTop />
          </React.Suspense>
        </BrowserRouter>
      </>
    )
  }
}
const mapStateToProps = (state) => ({
  token: makeSelectTokenId(state),
  isEmailVerified: getEmailVerifiedAt(state),
  isShowErrorToast: isShowErrorToast(state),
  toastErrors: getToastErrors(state),
  isShowSuccessToast: isShowSuccessToast(state),
  toastMessage: getToastMessage(state),
  isShowSpinner: isShowSpinner(state),
  spinnerMessage: getSpinnerMessage(state),
})
const mapDispatchToProps = (dispatch) => {
  return {
    hideErrorToast: () => dispatch(hideErrorToastAction()),
    hideSuccessToast: () => dispatch(hideSuccessToastAction()),
    hideSpinner: () => dispatch(hideSpinnerAction()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App)
