import sha1 from 'sha1'
import owasp from 'owasp-password-strength-test'

import { createTypes, createAction, transformNetworkError } from 'utils/actions'

import { post } from 'utils/request'
import { makeSelectTokenId } from './selectors'
import {
  hideSpinnerAction,
  showErrorToastAction,
  showSpinnerAction,
  showSuccessToastAction,
} from '../actions'
import { navigateToPage } from '../../utils/route'

const key = process.env.REACT_APP_ENCRYPTION_KEY

const REGISTER = createTypes('ZOOM/AuthPage/REGISTER')
const LOGIN = createTypes('ZOOM/AuthPage/LOGIN')
const LOGOUT = 'ZOOM/AuthPage/LOGOUT'
const PASSWORD_RESET_LINK = createTypes('ZOOM/AuthPage/PASSWORD_RESET_LINK')
const VERIFY_PASSWORD_TOKEN = createTypes('ZOOM/AuthPage/VERIFY_PASSWORD_TOKEN')
const RESET_PASSWORD = createTypes('ZOOM/AuthPage/RESET_PASSWORD')
const CHANGE_PASSWORD = createTypes('ZOOM/AuthPage/CHANGE_PASSWORD')
const VALIDATE_TOKEN = createTypes('ZOOM/AuthPage/VALIDATE_TOKEN')
const VERIFY = createTypes('ZOOM/AuthPage/VERIFY')
const VERIFY_DOMAIN = createTypes('ZOOM/AuthPage/VERIFY_DOMAIN')

function loginActionSuccess(resp) {
  return loginAction.success({ ...resp.user, token: resp.token })
}

function registerActionSuccess(resp) {
  return registerAction.success({ ...resp.user, token: resp.token })
}
function verifyActionSuccess(resp) {
  return verifyAction.success(resp)
}
function verifyActionFailed() {
  return verifyAction.failed()
}
function verifyDomainActionSuccess(resp) {
  return verifyDomainAction.success(resp)
}
const registerAction = {
  do: () => createAction(REGISTER.DO, {}),
  success: (params) => {
    return createAction(REGISTER.SUCCESS, { ...params })
  },
  failed: (error) => createAction(REGISTER.FAILED, { error }),
}

const loginAction = {
  do: () => createAction(LOGIN.DO, {}),
  success: (params) => createAction(LOGIN.SUCCESS, { ...params }),
  failed: (error) => createAction(LOGIN.FAILED, { error }),
}
const verifyAction = {
  failed: () => createAction(VERIFY.FAILED),
  success: (params) =>
    createAction(VERIFY.SUCCESS, { email_verified_at: params.email_verified_at }),
}

const logoutAction = () => createAction(LOGOUT, {})

const passwordResetLinkAction = {
  do: () => createAction(PASSWORD_RESET_LINK.DO, {}),
  success: (email) => createAction(PASSWORD_RESET_LINK.SUCCESS, { email }),
  failed: (error) => createAction(PASSWORD_RESET_LINK.FAILED, { error }),
}

const resetPasswordAction = {
  do: () => createAction(RESET_PASSWORD.DO, {}),
  success: (token) => createAction(RESET_PASSWORD.SUCCESS, { token }),
  failed: (error) => createAction(RESET_PASSWORD.FAILED, { error }),
}

const changePasswordAction = {
  do: () => createAction(CHANGE_PASSWORD.DO, {}),
  success: () => createAction(CHANGE_PASSWORD.SUCCESS),
  failed: (error) => createAction(CHANGE_PASSWORD.FAILED, { error }),
}

const verifyDomainAction = {
  do: () => createAction(VERIFY_DOMAIN.DO, {}),
  success: (params) => {
    return createAction(VERIFY_DOMAIN.SUCCESS, { ...params })
  },
  failed: (error) => createAction(VERIFY_DOMAIN.FAILED, { error }),
}

const checkPasswordComplexity = (password) => {
  const optionalTestsNeeded = 3

  owasp.config({
    allowPassphrases: false,
    minLength: 8,
    minOptionalTestsToPass: optionalTestsNeeded,
  })
  const result = owasp.test(password)

  if (!result.strong) {
    throw new Error(
      [
        'Password is not sufficiently strong.',
        result.requiredTestErrors.join(' '),
        result.optionalTestsPassed < optionalTestsNeeded
          ? `The password must contain ${optionalTestsNeeded} classes of characters.`
          : '',
      ].join(' '),
    )
  }
}

const register = async (postData) => async (dispatch) => {
  try {
    dispatch(registerAction.do())
    const resp = await post('auth/register', postData)
    dispatch(registerActionSuccess(resp))
    dispatch(showSuccessToastAction('Successfully registered.'))
    return resp
  } catch (error) {
    dispatch(registerAction.failed(error.body))
    throw error
  }
}

const login =
  ({ email, password }) =>
  async (dispatch) => {
    try {
      dispatch(loginAction.do())
      const postData = {
        email,
        password,
      }
      const resp = await post('auth/login', postData)
      dispatch(loginActionSuccess(resp))
      dispatch(showSuccessToastAction('Successfully logged in.'))

      const tokenNew = resp.token
      const hash = customEncrypt(tokenNew, key)
      if (hash) {
        navigateToPage(resp.url + '/verify-login?valid=' + resp.isValid + '&token=' + hash)
      }
    } catch (error) {
      dispatch(showErrorToastAction(error.body))
      dispatch(loginAction.failed(error.body))
    }
  }

const customEncrypt = (text, key) => {
  let result = ''
  for (let i = 0; i < text.length; i++) {
    const charCode = text.charCodeAt(i) + key.charCodeAt(i % key.length)
    result += String.fromCharCode(charCode)
  }
  return btoa(result) // Base64 encode the result
}
const logout = () => (dispatch) => {
  dispatch(logoutAction())
  navigateToPage('/landing')
}

const passwordResetLink = (postData) => async (dispatch) => {
  dispatch(hideSpinnerAction())
  try {
    dispatch(passwordResetLinkAction.do())
    const resp = await post('auth/reset-password', postData)
    dispatch(passwordResetLinkAction.success(postData))
    dispatch(hideSpinnerAction())
    return resp
  } catch (error) {
    dispatch(passwordResetLinkAction.failed(error))
  }
}

const changePassword = (oldPassword, newPassword) => async (dispatch, getState) => {
  try {
    dispatch(changePasswordAction.do())
    const token = makeSelectTokenId(getState())
    if (token) {
      checkPasswordComplexity(newPassword)
      const postData = {
        oldPassword: btoa(sha1(oldPassword)),
        newPassword: btoa(sha1(newPassword)),
      }
      await post('auth/password', postData, { Authorization: token })
      dispatch(changePasswordAction.success())
    }
  } catch (error) {
    dispatch(changePasswordAction.failed(transformNetworkError(error)))
  }
}

const verifyEmail = (code, email) => async (dispatch) => {
  dispatch(showSpinnerAction())
  try {
    const postData = { code, email }
    const resp = await post('auth/verify-email', postData)
    dispatch(verifyActionSuccess(resp))
    dispatch(hideSpinnerAction())
    const location = window.location
    location.href = process.env.REACT_APP_LOGIN_URL
  } catch (error) {
    dispatch(verifyActionFailed())
    dispatch(showErrorToastAction(error.body))
  }
}

const verifyDomain = async (postData) => async (dispatch) => {
  try {
    dispatch(verifyDomainAction.do())
    const resp = await post('auth/verify-domain', postData)
    dispatch(verifyDomainActionSuccess(resp))
    return resp
  } catch (error) {
    dispatch(verifyDomainAction.failed(error.body))
    throw error
  }
}

const verifyCode = (postData) => async (dispatch) => {
  dispatch(showSpinnerAction())
  try {
    const resp = await post('auth/verify-code', postData)
    dispatch(verifyActionSuccess(resp))
    dispatch(hideSpinnerAction())
    return resp
  } catch (error) {
    dispatch(verifyActionFailed())
    dispatch(showErrorToastAction(error.body))
  }
}

const resetPassword = (data) => async (dispatch) => {
  dispatch(showSpinnerAction())
  try {
    dispatch(resetPasswordAction.do())
    if (data.password !== data.confirmPassword || !data.password) {
      throw new Error("Passwords don't match")
    }
    checkPasswordComplexity(data.password)
    const postData = {
      token: data.token,
      password: data.password,
    }
    const resp = await post('auth/set-password', postData)
    dispatch(verifyActionSuccess(resp))
    dispatch(hideSpinnerAction())
    return resp
  } catch (error) {
    dispatch(verifyActionFailed())
    dispatch(showErrorToastAction(error.body))
  }
}
export {
  LOGIN,
  REGISTER,
  LOGOUT,
  PASSWORD_RESET_LINK,
  VERIFY_PASSWORD_TOKEN,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  VALIDATE_TOKEN,
  VERIFY,
  VERIFY_DOMAIN,
  checkPasswordComplexity,
  register,
  login,
  logout,
  changePassword,
  passwordResetLink,
  resetPassword,
  verifyEmail,
  verifyDomain,
  verifyCode,
}
