import { createAction } from '../utils/actions'

export const SHOW_ERROR_TOAST = 'ZOOM/Global/SHOW_ERROR_TOAST'
export const HIDE_ERROR_TOAST = 'ZOOM/Global/HIDE_ERROR_TOAST'
export const SHOW_SUCCESS_TOAST = 'ZOOM/Global/SHOW_SUCCESS_TOAST'
export const HIDE_SUCCESS_TOAST = 'ZOOM/Global/HIDE_SUCCESS_TOAST'
export const SHOW_SPINNER = 'ZOOM/Global/SHOW_SPINNER'
export const HIDE_SPINNER = 'ZOOM/Global/HIDE_SPINNER'

const showErrorToastAction = (errors) => createAction(SHOW_ERROR_TOAST, { errors })
const hideErrorToastAction = () => createAction(HIDE_ERROR_TOAST, {})
const showSuccessToastAction = (message) => createAction(SHOW_SUCCESS_TOAST, { message })
const hideSuccessToastAction = () => createAction(HIDE_SUCCESS_TOAST, {})
const showSpinnerAction = (message = 'Loading...') => createAction(SHOW_SPINNER, { message })
const hideSpinnerAction = () => createAction(HIDE_SPINNER, {})

export {
  showErrorToastAction,
  hideErrorToastAction,
  showSuccessToastAction,
  hideSuccessToastAction,
  showSpinnerAction,
  hideSpinnerAction,
}
