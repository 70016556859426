import 'whatwg-fetch'

const apiRoot = process.env.REACT_APP_API_URL + 'central/'

function parseJSON(response) {
  return response
}

async function checkStatus(response) {
  const json = await response.json()
  let error
  if (!response.ok) {
    error = new Error()
    if (
      !(
        response.headers.has('Content-Type') &&
        response.headers.get('Content-Type').includes('application/json')
      )
    ) {
      error.body = {
        message: 'Response is not JSON',
      }
    } else {
      if (json.errors) error.body = json.errors
      if (json.error) error.body = json.error
      if (json.message) error.body = json.message
    }
    throw error
  } else {
    return json
  }
}

export default function request(url, options) {
  return fetch(url, options).then(checkStatus).then(parseJSON)
}

export function get(url) {
  return request(`${apiRoot}${url}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  })
}

export function post(url, postData, headers) {
  return request(`${apiRoot}${url}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: (headers && headers.Authorization) || undefined,
    },
    body: JSON.stringify(postData),
  })
}
