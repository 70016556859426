import React from 'react'
import {
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
} from '@coreui/react-pro'
import styled from 'styled-components'
const Wrapper = styled.div`
  .form-label {
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
  }
`

const Text = (props) => {
  const inputProps = { ...props }
  delete inputProps['children']
  delete inputProps['errorText']
  delete inputProps['label']
  return (
    <Wrapper>
      <div className="mb-3">
        {props.label && <CFormLabel htmlFor={props.name}>{props.label}</CFormLabel>}
        <CFormInput {...inputProps}>{props.children}</CFormInput>
        <CFormFeedback invalid>{props.errorText}</CFormFeedback>
      </div>
    </Wrapper>
  )
}

const Textarea = (props) => {
  return (
    <Wrapper>
      <div className="mb-3">
        {props.label && <CFormLabel htmlFor={props.name}>{props.label}</CFormLabel>}
        <CFormTextarea {...props} aria-label="With textarea" />
        <CFormFeedback invalid>{props.errorText}</CFormFeedback>
      </div>
    </Wrapper>
  )
}
const Select = (props) => {
  return (
    <Wrapper>
      <div className="mb-3">
        {props.label && <CFormLabel htmlFor={props.name}>{props.label}</CFormLabel>}
        <CFormSelect className="mb-3" aria-label="Large select example" {...props}>
          {!props.defaultText && <option>--Select--</option>}
          {props.items &&
            props.items.map((item, key) => (
              <option key={key} value={item.value}>
                {item.label}
              </option>
            ))}
        </CFormSelect>
        <CFormFeedback invalid>{props.errorText}</CFormFeedback>
      </div>
    </Wrapper>
  )
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Text,
  Select,
  Textarea,
}
