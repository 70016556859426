import { fromJS } from 'immutable'
import { combineReducers } from 'redux-immutablejs'
import { LOCATION_CHANGE } from 'react-router-redux'

import { LOGOUT, VALIDATE_TOKEN } from 'views/user/actions'
import authReducer from 'views/user/reducer'
import { clearReducers } from 'utils/reducerUtils'
import globalReducer from './views/reducers'

const routeInitialState = fromJS({
  location: null,
})

const sidebarInitialState = {
  sidebarShow: true,
  asideShow: false,
}

const sidebarReducer = (state = sidebarInitialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

function routeReducer(state = routeInitialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return state.merge({
        location: action.payload,
      })
    default:
      return state
  }
}

export default function createReducer(injectedReducers) {
  const appReducer = combineReducers({
    route: routeReducer,
    auth: authReducer,
    sidebar: sidebarReducer,
    global: globalReducer,
    ...injectedReducers,
  })

  return (state, action) => {
    if (action.type === VALIDATE_TOKEN.DO) {
      return clearReducers(appReducer, state, action, {
        except: [[]],
      })
    }
    if (action.type === LOGOUT) {
      return clearReducers(appReducer, state, action, {
        except: [[]],
      })
    }
    return appReducer(state, action)
  }
}
