import React, { useEffect } from 'react'
import { ZToast, ZToastBody, ZToastClose } from '../../shared'
import { useDispatch } from 'react-redux'
import { hideSuccessToastAction } from '../../views/actions'

const SuccessToast = ({ message }) => {
  const dispatch = useDispatch()
  const dismiss = () => dispatch(hideSuccessToastAction())
  useEffect(() => {
    return () => {
      dispatch(hideSuccessToastAction())
    }
  }, [dispatch])
  return (
    <ZToast
      autohide={true}
      visible={true}
      delay={5000}
      color="success"
      className="text-white align-items-center"
      onClose={() => dismiss()}
    >
      <div className="d-flex">
        <ZToastBody>
          <p className="text-white">{message}</p>
        </ZToastBody>
        <ZToastClose className="me-2 m-auto" white />
      </div>
    </ZToast>
  )
}
export default SuccessToast
